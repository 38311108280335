import styled from 'styled-components'

export const MatchViewsDiv = styled.div`
    height: 80px;
    min-width: 12em;
    background-color: #332e2e;
    color: white;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
`

export const UniqueViewersDiv = styled.div`
    height: 80px;
    min-width: 13em;
    background-color: #332e2e;
    color: white;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    margin-left: 1.5em;
`
