import * as z from 'zod'
import { processDatadogRumAction } from '../datadog/datadog-actions'
import { raiseEvent } from '../gtm/gtm'

const schema = z.object({
    user_id: z.union([z.number(), z.string()]).optional(),
})

export type UserIdSchema = z.infer<typeof schema>

const eventName = 'user.auth'

export function userIdEvent({
    input,
    gtm,
    datadog,
}: {
    input: UserIdSchema
    gtm?: boolean
    datadog?: boolean
}) {
    gtm &&
        raiseEvent({
            type: eventName,
            payload: schema.parse(input),
        })

    datadog &&
        processDatadogRumAction(eventName, {
            userId: input,
        })
}
