import { useUser } from '@auth0/nextjs-auth0'
import { Text } from 'components/Text'
import { useRef } from 'react'
import { toast } from 'react-toastify'

function BillingButton() {
    const { user } = useUser()

    const formRef = useRef<HTMLFormElement>(null)

    const clickHandler = () => {
        if (!user?.stripe_customer_id) {
            return toast.warn('Need to Subscribe first to view page.')
        }

        formRef?.current && formRef?.current.submit()
    }

    return (
        <>
            <Text fColor="white" fSize={0.875} onClick={clickHandler}>
                {'Manage Billing'}
            </Text>
            <form
                ref={formRef}
                action="/api/stripe/billing_session"
                method="POST"
            />
        </>
    )
}

export default BillingButton
