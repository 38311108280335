import { useUser } from '@auth0/nextjs-auth0'
import { DownIcon } from 'assets/icon'
import LogoImage from 'assets/images/layout/logo.png'
import BHPLogo from 'assets/images/logo/bhp_horizontal.png'
import { ContainerWrapper } from 'components/Container'
import { Image } from 'components/Image'
import { Col, Row } from 'components/Layout'
import ProfileDropdown from 'components/ProfileDropdown'
import { Text } from 'components/Text'
import { siteSettings, useRouter } from 'hooks'
import _ from 'lodash'
import Link from 'next/link'
import router from 'next/router'
import { useEffect, useState } from 'react'
import { HiMenu } from 'react-icons/hi'
import { getCurrentPageName } from 'utils/common-helper'
import { trackEvent } from 'utils/helper-amplitude'
import BillingButton from '../BillingButton'
import {
    HeaderContainer,
    HeaderWrapper,
    LogoDivider,
    LogoWrapper,
    MenuItem,
    MenuItemBody,
    MenuItemList,
    MenuItemUnorderedList,
    SandwichMenuButton,
    SignUpButton,
    SingleLogoWrapper,
} from './Header.style'

// Yamata
export const MobileHeader = (props: any) => {
    const { menu, showBHPLogo } = props
    const { move, param, asPath }: any = useRouter()
    const [show, setShow] = useState<boolean>(false)
    const [showUserProfile, setShowUserProfile] = useState<boolean>(false)
    const { user } = useUser()
    const handleMenuClick = (to: any) => {
        move(to)
        setShow(false)
    }

    useEffect(() => {
        !show && setShowUserProfile(false)
    }, [show])

    const trackLoginSignup = () => {
        trackEvent(user, 'Login / Signup attempt', {
            pageName: getCurrentPageName(router.pathname),
        })
    }

    const paramName = !_.isUndefined(user)
        ? `${user?.first_name} ${user?.last_name}`
        : ''

    return (
        <HeaderWrapper>
            <ContainerWrapper>
                <Row
                    alignItems="center"
                    justifyContent="space-between"
                    padding={'14px 0 14px 0'}
                >
                    <Col>
                        <Row alignItems="center" gap={10}>
                            <SandwichMenuButton onClick={() => setShow(!show)}>
                                <HiMenu size={26} />
                            </SandwichMenuButton>
                            <LogoWrapper>
                                <Link href="/" legacyBehavior>
                                    <SingleLogoWrapper
                                        bottom={-2}
                                        cursor="pointer"
                                    >
                                        <Image
                                            src={LogoImage}
                                            height={34}
                                            width={115}
                                        />
                                    </SingleLogoWrapper>
                                </Link>
                                {showBHPLogo && (
                                    <>
                                        <LogoDivider />
                                        <SingleLogoWrapper bottom={-4}>
                                            <Image
                                                src={BHPLogo}
                                                alt="Foundation Partner BHP"
                                                height={22}
                                                width={110}
                                            />
                                        </SingleLogoWrapper>
                                    </>
                                )}
                            </LogoWrapper>
                        </Row>
                    </Col>
                    <Col>
                        <Row alignItems="center" padding="0px 10px">
                            {user ? (
                                <ProfileDropdown isMobile={true} />
                            ) : (
                                <SignUpButton
                                    bColor="primary"
                                    bSize="big"
                                    onClick={() => {
                                        handleMenuClick(
                                            `/api/auth/signup?returnTo=${asPath}`,
                                        ),
                                            trackLoginSignup()
                                    }}
                                >
                                    {'Sign up'}
                                </SignUpButton>
                            )}
                        </Row>
                    </Col>
                </Row>
            </ContainerWrapper>
            <MenuItemBody>
                <MenuItemUnorderedList>
                    {show &&
                        menu.map((item: any, index: number) => {
                            return (
                                <MenuItemList
                                    mode={
                                        asPath === item.path ? 'true' : 'false'
                                    }
                                    key={index}
                                >
                                    <MenuItem href={item.path}>
                                        <Text
                                            fColor="white"
                                            fSize={1}
                                            hoverStyle={{
                                                fColor: 'white.200',
                                                hfWeight: '700',
                                            }}
                                        >
                                            {item.title}
                                        </Text>
                                    </MenuItem>
                                </MenuItemList>
                            )
                        })}
                    {user && show && (
                        <MenuItemList mode="true">
                            <MenuItem
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}
                                onClick={() =>
                                    setShowUserProfile(!showUserProfile)
                                }
                            >
                                <Text
                                    fColor="white"
                                    fSize={1}
                                    hoverStyle={{
                                        fColor: 'white.200',
                                        hfWeight: '700',
                                    }}
                                >
                                    {'User Profile'}
                                </Text>
                                {showUserProfile ? (
                                    <div
                                        style={{
                                            transform: 'rotate(180deg)',
                                        }}
                                    >
                                        <DownIcon />
                                    </div>
                                ) : (
                                    <div>
                                        <DownIcon />
                                    </div>
                                )}
                            </MenuItem>
                        </MenuItemList>
                    )}
                    {user && showUserProfile && (
                        <MenuItemList mode="true">
                            <MenuItem
                                onClick={() =>
                                    handleMenuClick(
                                        `/profile?n=${encodeURI(paramName)}`,
                                    )
                                }
                            >
                                <Text
                                    fColor="white"
                                    fSize={0.9}
                                    style={{ marginLeft: '10px' }}
                                    hoverStyle={{
                                        fColor: 'white.200',
                                        hfWeight: '700',
                                    }}
                                >
                                    {'My Profile'}
                                </Text>
                            </MenuItem>
                        </MenuItemList>
                    )}
                    {user && showUserProfile && (
                        <MenuItemList mode="true">
                            <MenuItem>
                                <Text
                                    tAlign="left"
                                    fColor="white"
                                    fSize={0.9}
                                    style={{ marginLeft: '10px' }}
                                    hoverStyle={{
                                        fColor: 'white.200',
                                        hfWeight: '700',
                                    }}
                                >
                                    <BillingButton />
                                </Text>
                            </MenuItem>
                        </MenuItemList>
                    )}
                    {show && siteSettings('header_menu.sign_up') && (
                        <MenuItemList mode="true">
                            <MenuItem
                                href={
                                    user
                                        ? '/api/auth/logout'
                                        : `/api/auth/login?returnTo=${asPath}`
                                }
                            >
                                <Text
                                    fColor="white"
                                    fSize={1}
                                    hoverStyle={{
                                        fColor: 'white.200',
                                        hfWeight: '700',
                                    }}
                                >
                                    {user ? 'Log Out' : 'Login / Sign up'}
                                </Text>
                            </MenuItem>
                        </MenuItemList>
                    )}
                    {show && (
                        <HeaderContainer onClick={(e) => setShow(false)} />
                    )}
                </MenuItemUnorderedList>
            </MenuItemBody>
        </HeaderWrapper>
    );
}
