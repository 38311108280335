import { gql } from '@apollo/client'

const GET_CLIP_ASSETS_BY_CLUB_ID = gql`
    query GetClipAssetsByClubId($club_id: Int!) {
        clip_asset_user_club(
            where: { club_id: { _eq: $club_id }, show_on_club: { _eq: true } }
        ) {
            club_id
            clip_asset {
                name
                playback_id
                id
                asset_id
            }
        }
    }
`

const SUB_FEATURE_CLIPS = gql`
    subscription MySubscription(
        $where: clip_asset_user_club_bool_exp = {}
        $limit: Int
    ) {
        clip_asset_user_club(
            where: $where
            limit: $limit
            order_by: { created_at: desc }
        ) {
            club_id
            club {
                club_admins {
                    user_id
                }
            }
            clip_asset {
                name
                playback_id
                id
                asset_id
                static_file_status
                event_stream {
                    id
                    name
                    league_id
                    start_datetime
                    league {
                        name
                    }
                }
                user {
                    first_name
                    last_name
                }
                match {
                    id
                    custom_opponent
                    round_name
                    league_id
                    start_datetime
                    league {
                        name
                    }
                    home_team {
                        league {
                            slug
                        }
                        club {
                            name
                            display_name
                            slug
                        }
                    }
                    away_team {
                        league {
                            slug
                        }
                        club {
                            name
                            display_name
                            slug
                        }
                    }
                }
            }
        }
    }
`

const GET_FEATURE_CLIPS = gql`
    query MySubscription(
        $where: clip_asset_user_club_bool_exp = {}
        $limit: Int
    ) {
        clip_asset_user_club(
            where: $where
            limit: $limit
            order_by: { created_at: desc }
        ) {
            club_id
            club {
                club_admins {
                    user_id
                }
            }
            clip_asset {
                name
                playback_id
                id
                asset_id
                static_file_status
                event_stream {
                    id
                    name
                    league_id
                    start_datetime
                    league {
                        name
                    }
                }
                user {
                    first_name
                    last_name
                }
                match {
                    id
                    custom_opponent
                    round_name
                    league_id
                    start_datetime
                    league {
                        name
                    }
                    home_team {
                        league {
                            slug
                        }
                        club {
                            name
                            display_name
                            slug
                        }
                    }
                    away_team {
                        league {
                            slug
                        }
                        club {
                            name
                            display_name
                            slug
                        }
                    }
                }
            }
        }
    }
`

const SUB_PLAYER_FEATURE_CLIPS = gql`
    subscription MySubscription(
        $where: clip_asset_players_bool_exp = {}
        $limit: Int
    ) {
        clip_asset_players(
            where: $where
            limit: $limit
            order_by: { created_at: desc }
        ) {
            club_id
            clip_asset {
                name
                playback_id
                id
                asset_id
                static_file_status
                user {
                    first_name
                    last_name
                }
                event_stream {
                    id
                    league_id
                    club_id
                    start_datetime
                    name
                }
                match {
                    id
                    round_name
                    league_id
                    start_datetime
                    home_team {
                        club {
                            name
                            display_name
                            slug
                        }
                    }
                    away_team {
                        club {
                            name
                            display_name
                            slug
                        }
                    }
                }
            }
        }
    }
`

const INSERT_CLIP_ASSET_USER_CLUB = gql`
    mutation InsertClipAssetUserClubMutation(
        $objects: [clip_asset_user_club_insert_input!] = {}
    ) {
        insert_clip_asset_user_club(objects: $objects) {
            affected_rows
            returning {
                id
                clip_asset_id
            }
        }
    }
`

const INSERT_CLIP_ASSET_PLAYERS = gql`
    mutation InsertClipAssetPlayersMutation(
        $objects: [clip_asset_players_insert_input!]!
    ) {
        insert_clip_asset_players(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`

const GET_MATCH_REPLAY = gql`
    query GetMatchReplay($where: matches_bool_exp = {}) {
        matches(where: $where) {
            round_name
            stream_info {
                id
                static_file_status
                video_asset_id
            }
            id
        }
    }
`

const GET_EVENT_STREAM_REPLAY = gql`
    query GetEventStreamReplay($where: event_streams_bool_exp) {
        event_streams(where: $where) {
            id
            name
            stream_info {
                id
                static_file_status
                video_asset_id
            }
        }
    }
`

const SUB_MATCH_CLIPS = gql`
    subscription subFeaturedClips($where: clip_asset_user_club_bool_exp) {
        clip_asset_user_club(where: $where, order_by: { created_at: desc }) {
            club_id
            clip_asset {
                name
                playback_id
                id
                match_id
                asset_id
                static_file_status
                user {
                    first_name
                    last_name
                }
                match {
                    id
                    custom_opponent
                    round_name
                    league_id
                    home_team {
                        club {
                            name
                            display_name
                            slug
                        }
                    }
                    away_team {
                        club {
                            name
                            display_name
                            slug
                        }
                    }
                }
            }
        }
    }
`
const UPDATE_CLIP_ASSET = gql`
    mutation updateClipAssets(
        $where: clip_assets_bool_exp!
        $set: clip_assets_set_input
    ) {
        update_clip_assets(where: $where, _set: $set) {
            affected_rows
        }
    }
`

const DELETE_CLIP = gql`
    mutation deleteClip($where: clip_assets_bool_exp!) {
        delete_clip_assets(where: $where) {
            affected_rows
        }
    }
`

const SUB_CLIP_COUNT = gql`
    subscription ClipIdsSubscription(
        $where: clip_asset_user_club_bool_exp = {}
    ) {
        clip_asset_user_club_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`

export default {
    GET_CLIP_ASSETS_BY_CLUB_ID,
    SUB_FEATURE_CLIPS,
    GET_FEATURE_CLIPS,
    SUB_PLAYER_FEATURE_CLIPS,
    INSERT_CLIP_ASSET_USER_CLUB,
    INSERT_CLIP_ASSET_PLAYERS,
    GET_MATCH_REPLAY,
    SUB_MATCH_CLIPS,
    UPDATE_CLIP_ASSET,
    GET_EVENT_STREAM_REPLAY,
    DELETE_CLIP,
    SUB_CLIP_COUNT,
}
