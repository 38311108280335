import { CommonDiv } from 'components/Container'
import { GPTSlot, Sizes, useGPT } from 'goopubtag'

import { useRouter } from 'hooks'
import { getSiteSettings } from 'hooks/settings'
import { useCallback, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { AppState } from 'redux/store'
import { GPT_UNIT } from 'utils/constData'
import { GPTWrapper } from './AdsGPT.style'

export interface AdsProps {
    slot?: string
    mobileSize?: Sizes
    desktopSize?: Sizes
    pos?: number
    isMobile?: boolean
    siteSportName?: string
    clubName?: string
    matchHomeClubName?: string
    matchAwayClubName?: string
    matchLeagueSlug?: string
    matchSportName?: string
    eventOrganiser?: string
    eventName?: string
    streamStatus?: string
    customOpponent?: string
    eventLeagueSportName?: string
    eventClubSportName?: string
    eventClubName?: string
    isFooter?: boolean
    isBillboard?: boolean
}

declare global {
    interface Window {
        pbjs: any
        googletag: any
    }
}

const bidAndRefresh = function (
    adPath: string,
    slot: string,
    dimension: Sizes | undefined,
    googletag: any,
    pbjs: any,
) {
    if (!dimension) return

    const slotMap = [
        {
            name: adPath,
            divId: slot,
            sizes: [{ w: dimension![0], h: dimension![1] }],
        },
    ]

    function failsafeHandler(callback: any, initialSlots?: any) {
        let adserverRequestSend = false
        return (bidsBackSlots: any) => {
            if (adserverRequestSend) return
            adserverRequestSend = true
            callback(bidsBackSlots || initialSlots)
        }
    }

    const sendAdServerRequest = failsafeHandler((bids: any) => {
        googletag.cmd.push(function () {
            const slotsToRefresh: any[] = []
            slotMap.forEach((slotMapObject) => {
                pbjs.setTargetingForGPTAsync(
                    [slotMapObject.divId],
                    function (gptSlot: any) {
                        return function (slotId: any) {
                            if (
                                gptSlot.getTargeting('slotId').includes(slotId)
                            ) {
                                if (!slotsToRefresh.includes(gptSlot)) {
                                    slotsToRefresh.push(gptSlot)
                                }

                                return true
                            }
                        }
                    },
                )
            })
            if (slotsToRefresh.length) {
                googletag.pubads().refresh(slotsToRefresh)
            }
        })
    }, slotMap)

    pbjs.rp.requestBids({
        callback: sendAdServerRequest,
        slotMap: slotMap,
        divPatternMatching: true,
    })

    setTimeout(sendAdServerRequest, 3500)
}

const AdsGPT = ({
    slot = 'generic-ads',
    mobileSize = [320, 280],
    desktopSize = [728, 90],
    pos,
    siteSportName,
    clubName,
    matchHomeClubName,
    matchAwayClubName,
    matchLeagueSlug,
    matchSportName,
    eventOrganiser,
    eventName,
    streamStatus,
    customOpponent,
    eventLeagueSportName,
    eventClubSportName,
    eventClubName,
    isFooter,
    isBillboard,
}: AdsProps) => {
    const { param, router }: any = useRouter()
    const { league_slug }: any = router.query
    const currentSport = useSelector(
        (state: AppState) => state && state.site.currentSport,
    )
    const { setPageTargetingAttributes } = useGPT()
    const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
    const [dimension, setDimension] = useState<Sizes | undefined>(undefined)
    const [shouldRender, setShouldRender] = useState(false)
    const [inView, setInView] = useState(false)
    const [isFullscreen, setIsFullscreen] = useState(false)
    const ref = useRef(null)

    const useMagnite = getSiteSettings().global.magnite_script

    const sportname = currentSport
        ? `${currentSport.name}`.replace('-', '').toLowerCase()
        : ''
    const isLive = ['active', 'connected', 'recording'].includes(
        `${streamStatus}`,
    )
    const streamTargeting = isLive ? 'live' : 'vod'
    const targetClubs = [
        `${matchHomeClubName?.replace(/\s/g, '').toLowerCase()},${
            customOpponent
                ? customOpponent.replace(/\s/g, '').toLowerCase()
                : matchAwayClubName?.replace(/\s/g, '').toLowerCase()
        }`,
    ]
    const targetEventClubs = [
        `${eventClubName?.replace(/\s/g, '').toLowerCase()}`,
    ]

    // Set up the visibility detector
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setInView(entry.isIntersecting)
            },
            {
                root: null,
            },
        )

        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => {
            if (ref.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(ref.current)
            }
        }
    }, [])

    // Set up the full screen detector
    const handleFullscreenChange = useCallback((event: any) => {
        setIsFullscreen(
            typeof window !== undefined && !!window.document.fullscreenElement,
        )
    }, [])
    useEffect(() => {
        addEventListener('fullscreenchange', handleFullscreenChange, false)

        return () =>
            removeEventListener(
                'fullscreenchange',
                handleFullscreenChange,
                false,
            )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setDimension(
            isDesktop ? (isBillboard ? [970, 250] : desktopSize) : mobileSize,
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDesktop, useMediaQuery])

    let adPath = GPT_UNIT
    const targeting: Record<string, string | string[]> = {}
    let slotTargeting: Record<string, string | string[]> = {}
    if (pos) {
        slotTargeting['pos'] = pos.toString()
    }
    slotTargeting['slotId'] = slot

    switch (router.pathname) {
        // Home page
        case '/':
            adPath = GPT_UNIT + '/home'
            break

        // League page
        case '/league/[league_slug]':
            adPath =
                GPT_UNIT +
                `/${siteSportName
                    ?.replace(' ', '')
                    .toLowerCase()
                    .toLowerCase()}/${league_slug?.replace('-', '')}`
            break

        // Club page
        case '/club/[club_slug]':
            adPath =
                GPT_UNIT +
                `/${siteSportName
                    ?.replace(' ', '')
                    .toLowerCase()
                    .toLowerCase()}`
            targeting['club'] = clubName?.replace(/\s/g, '').toLowerCase() ?? ''
            break

        // Event page
        case '/events/[org_slug]':
            adPath =
                GPT_UNIT +
                `/${siteSportName
                    ?.replace(' ', '')
                    .toLowerCase()
                    .toLowerCase()}`
            targeting['eventOrg'] =
                eventOrganiser?.replace(/\s/g, '').toLowerCase() ?? ''
            targeting['event'] =
                eventName?.replace(/\s/g, '').toLowerCase() ?? ''
            break

        // Match page
        case '/match/[asset_id]':
            adPath =
                GPT_UNIT +
                `/${matchSportName
                    ?.replace(' ', '')
                    .toLowerCase()
                    .toLowerCase()}/${matchLeagueSlug?.replace('-', '')}`
            targeting['club'] = targetClubs
            targeting['stream'] = streamTargeting
            break
        case '/event/[event_id]/[event_slug]':
            adPath =
                GPT_UNIT +
                `/${eventLeagueSportName
                    ?.replace(' ', '')
                    .toLowerCase()}/${matchLeagueSlug?.replace('-', '')}`
            targeting['club'] = targetEventClubs
            targeting['stream'] = streamTargeting
            break

        default:
            break
    }

    slotTargeting = { ...slotTargeting, ...targeting }

    useEffect(() => {
        setPageTargetingAttributes(targeting)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targeting])

    let pbjs: any
    let googletag: any
    if (typeof window !== 'undefined') {
        pbjs = window.pbjs || { que: [] }
        googletag = window.googletag || { cmd: [] }

        if (useMagnite) {
            googletag.cmd.push(() => {
                googletag?.pubads().disableInitialLoad()
            })
        }
    }

    function refresh() {
        pbjs.que.push(function () {
            bidAndRefresh(adPath, slot, dimension, googletag, pbjs)
        })
    }

    // Initial load
    useEffect(() => {
        if (!dimension || shouldRender || !inView || isFullscreen) return

        setShouldRender(true)

        // This timeout is necessary as this useEffect is somehow triggered multiples times on page load.
        // The timeout with the clearTimeout below means only the last will take effect.
        const timeout = setTimeout(() => {
            if (useMagnite) {
                refresh()
            }
        }, 100)

        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dimension, inView, isFullscreen])

    // Subsequent 30 second refreshes
    useEffect(() => {
        if (!dimension) return

        const interval = setInterval(() => {
            if (useMagnite) {
                if (inView && !isFullscreen) {
                    refresh()
                } else {
                    setShouldRender(false)
                }
            }
        }, 30000)

        return () => clearInterval(interval)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dimension, inView, isFullscreen])

    return (
        <>
            {/* <Text>{loaded ? 'loaded' : 'na'}</Text> */}
            <GPTWrapper isFooter={isFooter} isBillboard={isBillboard} ref={ref}>
                <CommonDiv direction="row" justify="center">
                    {dimension && shouldRender ? (
                        <GPTSlot
                            adUnit={adPath}
                            slotId={slot}
                            sizes={dimension}
                            targetingArguments={slotTargeting}
                        />
                    ) : null}
                </CommonDiv>
            </GPTWrapper>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    siteSportName: state?.site?.currentSport?.name,
    clubName: state?.club?.info?.name,
    matchHomeClubName: state?.match?.info?.home_name,
    matchAwayClubName: state?.match?.info?.away_name,
    matchLeagueSlug: state?.match?.info?.league_slug,
    streamStatus: state?.match?.info?.status,
    matchSportName: state?.match?.info?.sports_name,
    eventLeagueSportName: state?.match?.info?.league_sports_name,
    eventClubSportName: state?.match?.info?.home_sports_name,
    eventClubName: state?.match?.info?.home_name,
    customOpponent: state?.match?.info?.custom_opponent?.club_name,
})
// @ts-ignore
export default connect(mapStateToProps)(AdsGPT)
