import { useQuery } from '@apollo/client'
import { PaymentStatusModal } from 'components/Modal'
import { useGPT } from 'goopubtag'
import { SITEQL } from 'graphql/club'
import { query } from 'graphql/stream'
import { siteSettings, useRouter, useUser } from 'hooks'
import { getSiteSettings } from 'hooks/settings'
import { useAdminAccess } from 'hooks/useAdminAccess'
import useUpdateGTMUserId from 'hooks/useUpdateGTMUserId'
import { Footer, Header } from 'layouts/app-layout/index'
import _ from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { setClubInfo, setFanPassModalShown } from 'redux/actions/club'
import { setLeagueInfo } from 'redux/actions/league'
import { setPlayerList } from 'redux/actions/players'
import { setSiteClubs, setSiteEvents, setSiteSport } from 'redux/actions/site'
import { setTeamList } from 'redux/actions/teams'
import { RootState } from 'redux/reducers/rootReducer'
import { getLeagueBySlugName } from 'restful-client'
import { getClubBySlugName } from 'restful-client/club'

/** List of no Club subpages */
const HomePages = ['/']

// -----------------------------------------------------------
const MenuItems = (
    league_slug: string,
    club_slug: string,
    away_club_slug: string | undefined,
    community_slug: string,
    event_details: { org_slug: string; event_slug: string } | undefined,
    path: string,
    user: any,
    club: any,
    league: any,
) => {
    const { showLeagueAdminLink, showClubAdminLink } = useAdminAccess(
        league_slug,
        club_slug,
    )
    const { showClubAdminLink: isAwayAdmin } = useAdminAccess(
        undefined,
        away_club_slug,
    )
    const showAwayClubAdminLink =
        isAwayAdmin && away_club_slug && club_slug !== away_club_slug

    // List for public pages
    const publicPages = [
        '/profile',
        '/main',
        '/screamer',
        '/about',
        '/dfp-about',
        '/register',
        '/terms-of-use',
        '/memberships',
        '/faq',
        '/clips/[playback_id]',
        '/get-streaming',
    ]

    let menus: any = [
        {
            title: 'Home',
            path: `/`,
            public: true,
            display: siteSettings('header_menu.home'),
        },
    ]

    if (
        HomePages.includes(path) ||
        publicPages.some((page) => path.includes(page))
    ) {
        menus.push(
            {
                title: 'Digital Fan Pass',
                path: `/dfp-about`,
                public: true,
                display: siteSettings('header_menu.about'),
            },
            {
                title: 'Get Streaming',
                path: `/get-streaming`,
                public: true,
                display: siteSettings('header_menu.get_streaming'),
            },
            {
                title: 'About',
                path: `/about`,
                public: true,
                display: siteSettings('header_menu.about'),
            },
            {
                title: 'Telethon',
                path: `/events/telethon/telethon2023`,
                public: true,
                display: siteSettings('header_menu.telethon'),
            },
        )
    } else if (
        path.includes('/event/') ||
        path.includes('/match/') ||
        path.includes('/clips/')
    ) {
        menus.push({
            title: 'League Home',
            path: `/league/${league_slug}`,
            public: true,
            display: siteSettings('header_menu.home'),
        })
        menus.push({
            title: league?.club_alias
                ? `${league.club_alias} Home`
                : 'Club Home',
            path: `/club/${club_slug}`,
            public: true,
            display: siteSettings('header_menu.home'),
        })
        showLeagueAdminLink &&
            menus.push({
                title: 'League Admin',
                path: `/league/${league_slug}/admin`,
                public: false,
                display: siteSettings('header_menu.admin'),
            })
        let homeAdminText = 'Club Admin'
        let awayAdminText = 'Club Admin'
        if (showClubAdminLink && showAwayClubAdminLink) {
            homeAdminText = 'Club Admin (Home)'
            awayAdminText = 'Club Admin (Away)'
        }
        showClubAdminLink &&
            menus.push({
                title: homeAdminText,
                path: `/club/${club_slug}/admin`,
                public: false,
                display: siteSettings('header_menu.admin'),
            })
        showAwayClubAdminLink &&
            menus.push({
                title: awayAdminText,
                path: `/club/${away_club_slug}/admin`,
                public: false,
                display: siteSettings('header_menu.admin'),
            })
    } else if (path.includes('/league/')) {
        menus.push({
            title: 'League Home',
            path: `/league/${league_slug}`,
            public: true,
            display: siteSettings('header_menu.home'),
        })

        showLeagueAdminLink &&
            menus.push({
                title: 'League Admin',
                path: `/league/${league_slug}/admin`,
                public: false,
                display: siteSettings('header_menu.admin'),
            })
    } else if (event_details || club_slug === 'telethon2023') {
        menus.push({
            title: 'Telethon',
            path: `/events/telethon/telethon2023`,
            public: true,
            display: true,
        })
        showClubAdminLink &&
            menus.push({
                title: 'Admin',
                path: `/club/${club_slug}/admin`,
                public: false,
                display: siteSettings('header_menu.admin'),
            })
    } else if (path.includes('/club/')) {
        menus.push(
            {
                title: league?.club_alias
                    ? `${league.club_alias} Home`
                    : 'Club Home',
                path: `/club/${club_slug}`,
                public: true,
                display: siteSettings('header_menu.home'),
            },
            {
                title: 'Live & Upcoming',
                path: `/club/${club_slug}/live`,
                public: true,
                display: siteSettings('header_menu.live'),
            },
            {
                title: 'Replays',
                path: `/club/${club_slug}/replays`,
                public: true,
                display: siteSettings('header_menu.replays'),
            },
            {
                title: 'Teams',
                path: `/club/${club_slug}/teams`,
                public: true,
                display: siteSettings('header_menu.teams'),
            },
            {
                title: 'Players',
                path: `/club/${club_slug}/players`,
                public: true,
                display: siteSettings('header_menu.players'),
            },
        )

        showClubAdminLink &&
            menus.push({
                title: 'Admin',
                path: `/club/${club_slug}/admin`,
                public: false,
                display: siteSettings('header_menu.admin'),
            })
    } else if (path.includes('/community/')) {
        menus.push({
            title: 'Community Home',
            path: `/community/${community_slug}`,
            public: true,
            display: true,
        })
    }

    const clubInCommunity = club?.community_members?.length
    const leagueInCommunity = league?.community_members?.length

    if (clubInCommunity) {
        club.community_members.forEach((cm: any) => {
            if (cm.community?.status === 'approved' || showClubAdminLink) {
                menus.push({
                    title: cm.community.display_name ?? cm.community.name,
                    path: `/community/${cm.community?.slug}`,
                    public: true,
                    display: true,
                })
            }
        })
    } else if (leagueInCommunity) {
        league.community_members.forEach((cm: any) => {
            if (cm.community?.status === 'approved' || showLeagueAdminLink) {
                menus.push({
                    title: cm.community.display_name ?? cm.community.name,
                    path: `/community/${cm.community?.slug}`,
                    public: true,
                    display: true,
                })
            }
        })
    }

    menus = _.filter(menus, ['display', true])

    return user ? menus : _.filter(menus, ['public', true])
}

export const AppLayout = (props: { children: any }) => {
    const dispatch = useDispatch()

    const { children } = props
    const clubInfo = useSelector((state: RootState) => state.club.info)
    const leagueInfo = useSelector((state: RootState) => state.league.info)
    const matchInfo = useSelector((state: RootState) => state.match.info)

    const { param, asPath, path }: any = useRouter()
    const { user, isAdmin, isDigitalFan } = useUser()

    useUpdateGTMUserId(user?.id)

    const menu = MenuItems(
        param?.league_slug ?? leagueInfo?.slug ?? '',
        param?.club_slug ?? clubInfo?.slug ?? '',
        matchInfo?.away_club_slug ?? undefined,
        param ? param?.community_slug : '',
        param && param.org_slug && param.event_slug
            ? {
                  org_slug: param.org_slug,
                  event_slug: param.event_slug,
              }
            : undefined,
        path,
        user,
        clubInfo,
        leagueInfo,
    )
    const { refresh } = useGPT()

    useEffect(() => {
        setInterval(function () {
            if (!getSiteSettings().global.magnite_script) {
                refresh() // Refreshes all the ads on the page
            }
        }, 30000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /** hydrate club redux */
    useEffect(() => {
        const getLeagueDataByLeagueSlugName = async () => {
            const data = await getLeagueBySlugName(param?.league_slug)

            const league = _.find(data?.leagues, ['slug', param.league_slug])
            dispatch(setLeagueInfo(league))
            dispatch(setSiteSport(league?.sports ?? null))
        }

        const getClubDataByClubSlugName = async () => {
            const data = await getClubBySlugName(param?.club_slug)

            dispatch(setClubInfo(data?.clubs[0] ?? {}))
            dispatch(setFanPassModalShown(false))
            dispatch(setPlayerList(data?.clubs[0]?.players ?? []))
            dispatch(setTeamList(data?.clubs[0]?.teams ?? []))
            dispatch(setSiteSport(data?.clubs[0]?.sport ?? null))
        }

        param?.league_slug && getLeagueDataByLeagueSlugName()
        param?.club_slug && getClubDataByClubSlugName()
    }, [param?.league_slug, param?.club_slug])

    const { data } = useQuery(SITEQL.GET_SITE_CLUBS, {
        variables: {
            where: {
                status: { _eq: 'approved' },
            },
        },
        onCompleted() {
            dispatch(setSiteClubs(data?.clubs))
        },
    })

    useQuery(query.GET_EVENT_COLLECTIONS, {
        onCompleted(data) {
            data && dispatch(setSiteEvents(data?.event_collections))
        },
    })

    return (
        <>
            <PaymentStatusModal />
            <Header menu={menu} />
            {children}
            <Footer />
            {/* <ScrollTop /> */}
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                theme="dark"
                pauseOnHover
            />
        </>
    )
}
