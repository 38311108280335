// Import Amplitude
import { UserProfile } from '@auth0/nextjs-auth0'
import amplitude from 'amplitude-js'

export const trackEvent = (
    user: UserProfile | undefined,
    event: string,
    prop?: any,
) => {
    amplitude
        .getInstance()
        .init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY || '')
    if (user) {
        amplitude.getInstance().setUserId(user?.id as string)
        amplitude.getInstance().logEvent(event, prop ?? {})
    } else {
        const deviceId = amplitude.getInstance().options.deviceId
        amplitude.getInstance().setDeviceId(deviceId || '')
        amplitude.getInstance().logEvent(event, prop ?? {})
    }
}
